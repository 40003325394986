import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
// import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import { StickyTopRowStack, StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import StoreFilter from "../../components/commonComponents/Filters/StoreFilter";
import DateRangeFilter from "../../components/commonComponents/Filters/DateRangeFilter";
// ,
import { checkWorkbookFilters, filterRangeValue } from "../../api_calls/TableauVisuals";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkWorkSpaceFromCookies } from "../../api_calls/userManagement";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import { useSelector } from "react-redux";
import {
  dbFormatDate,
  formatToStandardDate,
  resetBrandReportView,
  standardFormatToday,
  storeListOptionsForTableauFilter,
  yesterdayDate,
} from "../../api_calls/utils";

const Overview = () => {
  const [workSpaceId, setWorkSpaceId] = React.useState("");
  const userStateDate = useSelector((state) => state.userSelections);
  const startDate =
    typeof userStateDate.date_range_start_date !== "undefined"
      ? new Date(userStateDate.date_range_start_date)
      : new Date("2023-02-07");

  const today = standardFormatToday();
  const yesterday = formatToStandardDate(today);
  yesterday.setDate(today.getDate() - 1);

  React.useEffect(() => {
    const checkWorkSpaces = checkWorkSpaceFromCookies();

    if (
      checkWorkSpaces.success === true &&
      typeof checkWorkSpaces.current_workspace !== "undefined" &&
      typeof checkWorkSpaces.current_workspace.id !== "undefined"
    ) {
      setWorkSpaceId(checkWorkSpaces.current_workspace.id);
    }

    resetBrandReportView();
    // if (checkWorkSpaces.success === true && checkWorkSpaces.workspace_options.length > 1) {
    //   setWorkSpaceId(checkWorkSpaces?.current_workspace?.id);
    // }
  }, []);

  return workSpaceId !== "" ? (
    <StyledMainBox component="main">
      {/* ------------- filters -------------- */}
      <StickyTopRowStack direction={"row"} spacing={4}>
        {/* ------- stores filter ----------- */}
        <Box sx={{ width: 250 }}>
          <StoreFilter usedFor={"overview"} fieldName="Name Store" vizId={"generalKpiViz"} />
        </Box>
        {/* ------------ day timestamp filter ------------ */}
        {typeof userStateDate.date_range_start_date !== "undefined" && (
          <Box sx={{ width: 250 }}>
            <DateRangeFilter
              fieldName={"DAY(Timestamp)"}
              minSelectableDate={startDate}
              // maxSelectableDate={dbFormatDate(yesterday)}
              maxSelectableDate={new Date("2023-11-21")}
              onValueChange={filterRangeValue}
              vizId={"generalKpiViz"}
            />
          </Box>
        )}
      </StickyTopRowStack>
      {/* -------- tableau dashboard -------- */}
      <Box height={1} />
      <Grid container spacing={2}>
        {typeof userStateDate.stores_list !== "undefined" && (
          <TableauDash
            vizId="generalKpiViz"
            vizUrl={"https://dub01.online.tableau.com/t/tableaucyreende/views/" + workSpaceId + "_Overview/Overview"}
            applyFilter={true}
            filterConfig={{
              store: {
                name: "Name Store",
                values: storeListOptionsForTableauFilter("overview"),
              },
              // date_range: {
              //   name: "DAY(Timestamp)",
              //   values: [dbFormatDate(startDate), yesterdayDate()],
              // },
            }}
          />
        )}
      </Grid>
    </StyledMainBox>
  ) : (
    <></>
  );
};

export default AccessControl(Overview, "overview");
