import React from "react";
import { StickyTopRowStack } from "../../../../components/commonComponents/StyledComponents/styled";
import { Box } from "@mui/material";
import SelectBox from "../../../../components/commonComponents/Selectors/SelectBox";
import { useDispatch } from "react-redux";
import { updateStateData } from "../../../../Store/actions";
import { checkWorkSpaceFromCookies } from "../../../../api_calls/userManagement";
import SingleSelect from "../../../../components/commonComponents/Selectors/SingleSelect";

const GraffanaFilter = () => {
  const dispatchAction = useDispatch();
  const storeValues = [
    "Wondrous Store",
    "Phenomenal Store",
    "Awesome Store",
    "Superb Store",
    "Spectacular Store",
    "Magnificent Store",
    "Impeccable Store",
    "Creative Store",
    "Marvelous Store",
    "Fantastic Store",
  ];

  const [storeSelection, setStoreSelection] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [selectedStoreName, setSelectedStoreName] = React.useState({});

  const dashboardLinks = {
    "Wondrous Store": "https://grafana.cyreenapps.de/public-dashboards/a57c37eeaad14196bd814bf1e5901efd",
    "Phenomenal Store": "https://grafana.cyreenapps.de/public-dashboards/f266296537c942b5a86686cc95f66c2a",
    "Awesome Store": "https://grafana.cyreenapps.de/public-dashboards/bf94065840654a6c96a6705f52e7a370",
    "Superb Store": "https://grafana.cyreenapps.de/public-dashboards/d1a8ebef837b40dfaaf53f87a43b5e25",
    "Spectacular Store": "https://grafana.cyreenapps.de/public-dashboards/db89485eac0e4c8784cdbe976e05d073",
    "Magnificent Store": "https://grafana.cyreenapps.de/public-dashboards/1595f04369814cf48c0020725f10b594",
    "Impeccable Store": "https://grafana.cyreenapps.de/public-dashboards/4d2261d7cf9a475c9e3c6e7326ae2f71",
    "Creative Store": "https://grafana.cyreenapps.de/public-dashboards/d3221255f9ad43419843b01365782154",
    "Marvelous Store": "https://grafana.cyreenapps.de/public-dashboards/e3906faa1a2d4f0db5e75479aa634cae",
    "Fantastic Store": "https://grafana.cyreenapps.de/public-dashboards/fed42df6a45e4e278d588e6f8b5d3165",
  };

  React.useEffect(() => {
    setStoreSelection(storeValues[0]);
    setSelectedStoreName({ value: storeValues[0], label: storeValues[0] });

    dispatchAction(
      updateStateData({
        graffana_dashboard_link: dashboardLinks[storeValues[0]],
      })
    );

    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);

  return (
    <StickyTopRowStack direction={"row"} spacing={4}>
      {/* ------- stores filter ----------- */}
      <Box sx={{ width: 220 }}>
        {dataLoaded === true && (
          <SingleSelect
            enableFullWidth={true}
            label={"Store"}
            selectionCheck={(option, value) => option.label === value.label}
            options={storeValues.map((storeName) => {
              return { value: storeName, label: storeName };
            })}
            defaultVal={selectedStoreName}
            onChangeCall={(selectedOption) => {
              setSelectedStoreName(selectedOption);
              setStoreSelection(selectedOption.value);
              dispatchAction(
                updateStateData({
                  graffana_dashboard_link: dashboardLinks[selectedOption.value],
                })
              );
            }}
          />
        )}
      </Box>
    </StickyTopRowStack>
  );
};

export default GraffanaFilter;
