import * as React from "react";
import { filterSingleValue } from "../../../../api_calls/TableauVisuals";
import SingleSelect from "../../../../components/commonComponents/Selectors/SingleSelect";
import { useSelector } from "react-redux";
import { checkUserRole } from "../../../../api_calls/userManagement";

export default function AnalysisGroupSingle({ vizId, multiSelect }) {
  const userStateData = useSelector((state) => state.userSelections);
  const [selectedAnalysisGroup, setAnalysisGroup] = React.useState({});
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const userRole = userStateData?.brand_report_view === true ? { user_role: "Brand" } : checkUserRole();
  let adlevelNames =
    typeof userStateData.adlevel_names !== "undefined" && userStateData.adlevel_names.length !== 0
      ? [
          ...userStateData.adlevel_names.map((adlevelInfo) => {
            return adlevelInfo.label;
          }),
        ].sort()
      : [];

  if (userRole.user_role === "Brand") {
    // check brand filter object to fetch stores for brand (selected by retailer)
    adlevelNames = userStateData?.brand_filters?.performance_filter?.analysis_group
      ? multiSelect
        ? [...userStateData.brand_filters.performance_filter.analysis_group].sort()
        : [...userStateData.brand_filters.performance_filter.analysis_group].sort().filter((item) => item !== "(All)")
      : [];
  }

  const selectAnalysisGroupValues = (option) => {
    setAnalysisGroup({ value: option.value, label: option.value });
  };

  React.useEffect(() => {
    let defaultSelection = adlevelNames.length !== 0 ? adlevelNames[0] : "";
    setAnalysisGroup({ value: defaultSelection, label: defaultSelection });
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);

  return dataLoaded ? (
    <SingleSelect
      enableFullWidth={true}
      label={"Analysis Group"}
      selectionCheck={(option, value) => option.label === value.label}
      options={adlevelNames.map((groupName) => {
        return { value: groupName, label: groupName };
      })}
      defaultVal={selectedAnalysisGroup}
      onChangeCall={(selectedOption) => {
        let selectAdlevels = [];
        selectAnalysisGroupValues(selectedOption);
        if (selectedOption.value.includes("(All)")) {
          selectAdlevels = adlevelNames;
        } else {
          selectAdlevels = [selectedOption.value];
        }
        filterSingleValue("name_adlevel", selectAdlevels, vizId);
      }}
    />
  ) : (
    <></>
  );
}