// TermsOfUse.js
import React from "react";
import { Typography, Container, Box, List, ListItem } from "@mui/material";
import LogoRed from "../../images/red_C_cyreen_logo.png";
import { LogoImage } from "../../components/commonComponents/StyledComponents/styled";
const TermsOfUse = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="body1">
          <LogoImage src={LogoRed} alt="Logo" width={"80"} />
        </Typography>
        <Box height={20} />
        <Typography variant="h4" component="h1" gutterBottom>
          TERMS OF USE
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last updated February 23, 2024
        </Typography>
        <Typography variant="body1">
          <strong>AGREEMENT TO OUR LEGAL TERMS</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We are Cyreen GmbH ("Company," "we," "us," "our"), a company registered in Germany at Schaumainkai 87-91,
          Frankfurt am Main, Hessen 60596. Our VAT number is DE312774025.
        </Typography>
        {/* Add more content here as needed */}
        <Box height={20} />
        <Typography variant="body1">
          We operate the website demo-explorer.cyreen.de (the "Site"), as well as any other related products and services
          that refer or link to these legal terms (the "Legal Terms") (collectively, the "Services").
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          Non-exhaustive description of the Services: A) Provide a demo access to the CAP Explorer platform developed and
          provided by us, B) The demo access is meant for getting insights into the services provided by us and might not
          include all functionalities of the Services which we provide to our customers, and C) In general, CAP Explorer is
          a service platform designed to be used in combination with our C.A.P. in-store retail media technology and
          software
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          You can contact us by phone at +49 (613) 45898677 0, email at info@cyreen.de, or by mail to Schaumainkai 87-91,
          Frankfurt am Main, Hessen 60596, Germany.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an
          entity ("you"), and Cyreen GmbH, concerning your access to and use of the Services. You agree that by accessing
          the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE
          WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE
          USE IMMEDIATELY.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby
          expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or
          modifications to these Legal Terms from time to time. We will alert you about any changes by updating the "Last
          updated" date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is
          your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to,
          and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your
          continued use of the Services after the date such revised Legal Terms are posted.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted
          to use or register for the Services.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">We recommend that you print a copy of these Legal Terms for your records.</Typography>
        <Box height={20} />
        {/* ----------- table of content ----------- */}
        <Typography variant="h4" component="h1" gutterBottom>
          TABLE OF CONTENTS
        </Typography>

        <Typography variant="h6" gutterBottom>
          <a href="#our_service">1. OUR SERVICES</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#intellectual_property">2. INTELLECTUAL PROPERTY RIGHTS</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#user_presentations">3. USER REPRESENTATIONS</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#user_registration">4. USER REGISTRATION</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#prohibited_activities">5. PROHIBITED ACTIVITIES</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#user_generated_contributions">6. USER GENERATED CONTRIBUTIONS</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#contribution_license">7. CONTRIBUTION LICENSE</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#services_management">8. SERVICES MANAGEMENT</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#privacy_policy">9. PRIVACY POLICY</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#copyright_infringement">10. COPYRIGHT INFRINGEMENTS</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#terms_and_termination">11. TERM AND TERMINATION</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#modifications_and_interruptions">12. MODIFICATIONS AND INTERRUPTIONS</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#governing_law">13. GOVERNING LAW</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#dispute_resolution">14. DISPUTE RESOLUTION</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#corrections">15. CORRECTIONS</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#disclaimer">16. DISCLAIMER</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#limitations_of_liability">17. LIMITATIONS OF LIABILITY</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#identification">18. INDEMNIFICATION</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#user_data">19. USER DATA</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#e_communication">20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#california_user">21. CALIFORNIA USERS AND RESIDENTS</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#misc">22. MISCELLANEOUS</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          <a href="#contact_us">23. CONTACT US</a>
        </Typography>
        <Box height={30} />
        {/* ----------- table of content ----------- */}
        <Typography variant="body1">
          <strong> 1. OUR SERVICES</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="our_service">
          The information provided when using the Services is not intended for distribution to or use by any person or
          entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those
          persons who choose to access the Services from other locations do so on their own initiative and are solely
          responsible for compliance with local laws, if and to the extent local laws are applicable.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and
          Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions
          would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would
          violate the Gramm-Leach-Bliley Act (GLBA).
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="intellectual_property">
          <strong>2. INTELLECTUAL PROPERTY RIGHTS</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <strong>Our intellectual property</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We are the owner or the licensee of all intellectual property rights in our Services, including all source code,
          databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services
          (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the
          "Marks").
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property
          rights and unfair competition laws) and treaties in the United States and around the world.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use or
          internal business purpose only.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <strong>Your use of our Services</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          Subject to your compliance with these Legal Terms, including the{" "}
          <a href="#prohibited_activities">"PROHIBITED ACTIVITIES"</a> section below, we grant you a non-exclusive,
          non-transferable, revocable license to:
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <List>
            <ListItem>access the Services; and</ListItem>
            <ListItem>
              download or print a copy of any portion of the Content to which you have properly gained access.
            </ListItem>
          </List>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">solely for your personal, non-commercial use or internal business purpose.</Typography>
        <Box height={20} />
        <Typography variant="body1">
          Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks
          may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
          transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without
          our express prior written permission.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere
          in our Legal Terms, please address your request to: info@cyreen.de. If we ever grant you the permission to post,
          reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or
          licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is
          visible on posting, reproducing, or displaying our Content.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your
          right to use our Services will terminate immediately.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <strong>Your submissions and contributions</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          Please review this section and the <a href="#prohibited_activities">"PROHIBITED ACTIVITIES"</a> section carefully
          prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or
          upload any content through the Services.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <strong>Submissions:</strong> By directly sending us any question, comment, suggestion, idea, feedback, or other
          information about the Services ("Submissions"), you agree to assign to us all intellectual property rights in such
          Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination
          for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <strong>Contributions:</strong> The Services may invite you to chat, contribute to, or participate in blogs,
          message boards, online forums, and other functionality during which you may create, submit, post, display,
          transmit, publish, distribute, or broadcast content and materials to us or through the Services, including but not
          limited to text, writings, video, audio, photographs, music, graphics, comments, reviews, rating suggestions,
          personal information, or other material ("Contributions"). Any Submission that is publicly posted shall also be
          treated as a Contribution.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          You understand that Contributions may be viewable by other users of the Services.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <strong>
            When you post Contributions, you grant us a license (including use of your name, trademarks, and logos):
          </strong>{" "}
          By posting any Contributions, you grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
          transferable, royalty-free, fully-paid, worldwide right, and license to: use, copy, reproduce, distribute, sell,
          resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat, translate, excerpt (in
          whole or in part), and exploit your Contributions (including, without limitation, your image, name, and voice) for
          any purpose, commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into other
          works, your Contributions, and to sublicense the licenses granted in this section. Our use and distribution may
          occur in any media formats and through any media channels.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          This license includes our use of your name, company name, and franchise name, as applicable, and any of the
          trademarks, service marks, trade names, logos, and personal and commercial images you provide.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <strong>You are responsible for what you post or upload:</strong> By sending us Submissions and/or posting
          Contributions through any part of the Services or making Contributions accessible through the Services by linking
          your account through the Services to any of your social networking accounts, you:
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <strong>You are responsible for what you post or upload:</strong> By sending us Submissions and/or posting
          Contributions through any part of the Services or making Contributions accessible through the Services by linking
          your account through the Services to any of your social networking accounts, you:
        </Typography>
        <Box height={20} />

        <Typography variant="body1">
          <List>
            <ListItem>
              confirm that you have read and agree with our <a href="#prohibited_activities">"PROHIBITED ACTIVITIES"</a> and
              will not post, send, publish, upload, or transmit through the Services any Submission nor post any
              Contribution that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive,
              discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or
              misleading;
            </ListItem>
            <ListItem>
              to the extent permissible by applicable law, waive any and all moral rights to any such Submission and/or
              Contribution;
            </ListItem>
            <ListItem>
              warrant that any such Submission and/or Contributions are original to you or that you have the necessary
              rights and licenses to submit such Submissions and/or Contributions and that you have full authority to grant
              us the above-mentioned rights in relation to your Submissions and/or Contributions; and
            </ListItem>
            <ListItem>
              warrant and represent that your Submissions and/or Contributions do not constitute confidential information.
            </ListItem>
          </List>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          You are solely responsible for your Submissions and/or Contributions and you expressly agree to reimburse us for
          any and all losses that we may suffer because of your breach of (a) this section, (b) any third party’s
          intellectual property rights, or (c) applicable law.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <strong>We may remove or edit your Content:</strong> Although we have no obligation to monitor any Contributions,
          we shall have the right to remove or edit any Contributions at any time without notice if in our reasonable
          opinion we consider such Contributions harmful or in breach of these Legal Terms. If we remove or edit any such
          Contributions, we may also suspend or disable your account and report you to the authorities.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <strong>Copyright infringement</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We respect the intellectual property rights of others. If you believe that any material available on or through
          the Services infringes upon any copyright you own or control, please immediately refer to the{" "}
          <a href="#copyright_infringement">"COPYRIGHT INFRINGEMENTS"</a> section below.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="user_presentations">
          <strong>3. USER REPRESENTATIONS</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          By using the Services, you represent and warrant that: (1) all registration information you submit will be true,
          accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such
          registration information as necessary; (3) you have the legal capacity and you agree to comply with these Legal
          Terms; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not access the Services
          through automated or non-human means, whether through a bot, script or otherwise; (6) you will not use the
          Services for any illegal or unauthorized purpose; and (7) your use of the Services will not violate any applicable
          law or regulation.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
          suspend or terminate your account and refuse any and all current or future use of the Services (or any portion
          thereof).
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="user_registration">
          <strong>4. USER REGISTRATION</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          You may be required to register to use the Services. You agree to keep your password confidential and will be
          responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a
          username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or
          otherwise objectionable.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="prohibited_activities">
          <strong>5. PROHIBITED ACTIVITIES</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          You may not access or use the Services for any purpose other than that for which we make the Services available.
          The Services may not be used in connection with any commercial endeavors except those that are specifically
          endorsed or approved by us.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">As a user of the Services, you agree not to:</Typography>
        <Box height={20} />
        <Typography variant="body1">
          <List>
            <ListItem>
              Systematically retrieve data or other content from the Services to create or compile, directly or indirectly,
              a collection, compilation, database, or directory without written permission from us.
            </ListItem>
            <ListItem>
              Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
              information such as user passwords.
            </ListItem>
            <ListItem>
              Circumvent, disable, or otherwise interfere with security-related features of the Services, including features
              that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services
              and/or the Content contained therein.
            </ListItem>
            <ListItem>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</ListItem>
            <ListItem>
              Use any information obtained from the Services in order to harass, abuse, or harm another person.
            </ListItem>
            <ListItem>Make improper use of our support services or submit false reports of abuse or misconduct.</ListItem>
            <ListItem>Use the Services in a manner inconsistent with any applicable laws or regulations.</ListItem>
            <ListItem>Engage in unauthorized framing of or linking to the Services.</ListItem>
            <ListItem>
              Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including
              excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with
              any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or
              interferes with the use, features, functions, operation, or maintenance of the Services.
            </ListItem>
            <ListItem>
              Engage in any automated use of the system, such as using scripts to send comments or messages, or using any
              data mining, robots, or similar data gathering and extraction tools.
            </ListItem>
            <ListItem>Delete the copyright or other proprietary rights notice from any Content.</ListItem>
            <ListItem>Attempt to impersonate another user or person or use the username of another user.</ListItem>
            <ListItem>
              Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
              information collection or transmission mechanism, including without limitation, clear graphics interchange
              formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as "spyware"
              or "passive collection mechanisms" or "pcms").
            </ListItem>
            <ListItem>
              Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to
              the Services.
            </ListItem>
            <ListItem>
              Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the
              Services to you.
            </ListItem>
            <ListItem>
              Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any
              portion of the Services.
            </ListItem>
            <ListItem>
              Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other
              code.
            </ListItem>
            <ListItem>
              Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the
              software comprising or in any way making up a part of the Services.
            </ListItem>
            <ListItem>
              Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or
              distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or
              offline reader that accesses the Services, or use or launch any unauthorized script or other software.
            </ListItem>
            <ListItem>Use a buying agent or purchasing agent to make purchases on the Services.</ListItem>
            <ListItem>
              Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by
              electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated
              means or under false pretenses.
            </ListItem>
            <ListItem>
              Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for
              any revenue-generating endeavor or commercial enterprise.
            </ListItem>
            <ListItem>Use the Services to advertise or offer to sell goods and services.</ListItem>
            <ListItem>Sell or otherwise transfer your profile.</ListItem>
          </List>
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="user_generated_contributions">
          <strong>6. USER GENERATED CONTRIBUTIONS</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and
          other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform,
          publish, distribute, or broadcast content and materials to us or on the Services, including but not limited to
          text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other
          material (collectively, "Contributions"). Contributions may be viewable by other users of the Services and through
          third-party websites. As such, any Contributions you transmit may be treated as non-confidential and
          non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that:
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <List>
            <ListItem>
              The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or
              copying of your Contributions do not and will not infringe the proprietary rights, including but not limited
              to the copyright, patent, trademark, trade secret, or moral rights of any third party.
            </ListItem>
            <ListItem>
              You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions
              to use and to authorize us, the Services, and other users of the Services to use your Contributions in any
              manner contemplated by the Services and these Legal Terms.
            </ListItem>
            <ListItem>
              You have the written consent, release, and/or permission of each and every identifiable individual person in
              your Contributions to use the name or likeness of each and every such identifiable individual person to enable
              inclusion and use of your Contributions in any manner contemplated by the Services and these Legal Terms.
            </ListItem>
            <ListItem>Your Contributions are not false, inaccurate, or misleading.</ListItem>
            <ListItem>
              Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes,
              chain letters, spam, mass mailings, or other forms of solicitation.
            </ListItem>
            <ListItem>
              Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or
              otherwise objectionable (as determined by us).
            </ListItem>
            <ListItem>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</ListItem>
            <ListItem>
              Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and
              to promote violence against a specific person or class of people.
            </ListItem>
            <ListItem>Your Contributions do not violate any applicable law, regulation, or rule.</ListItem>
            <ListItem>Your Contributions do not violate the privacy or publicity rights of any third party.</ListItem>
            <ListItem>
              Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to
              protect the health or well-being of minors.
            </ListItem>
            <ListItem>
              Your Contributions do not include any offensive comments that are connected to race, national origin, gender,
              sexual preference, or physical handicap.
            </ListItem>
            <ListItem>
              Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal
              Terms, or any applicable law or regulation.
            </ListItem>
          </List>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other
          things, termination or suspension of your rights to use the Services.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="contribution_license">
          <strong>7. CONTRIBUTION LICENSE</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          By posting your Contributions to any part of the Services, you automatically grant, and you represent and warrant
          that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
          transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose,
          sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat,
          translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without
          limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare
          derivative works of, or incorporate into other works, such Contributions, and grant and authorize sublicenses of
          the foregoing. The use and distribution may occur in any media formats and through any media channels.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          This license will apply to any form, media, or technology now known or hereafter developed, and includes our use
          of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade
          names, logos, and personal and commercial images you provide. You waive all moral rights in your Contributions,
          and you warrant that moral rights have not otherwise been asserted in your Contributions.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and
          any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable
          for any statements or representations in your Contributions provided by you in any area on the Services. You are
          solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all
          responsibility and to refrain from any legal action against us regarding your Contributions.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any
          Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations on the Services;
          and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no
          obligation to monitor your Contributions.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="services_management">
          <strong>8. SERVICES MANAGEMENT</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms;
          (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal
          Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole
          discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the
          extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content
          that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a
          manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="privacy_policy">
          <strong>9. PRIVACY POLICY</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We care about data privacy and security. Please review our Privacy Policy:
          <a href="https://www.cyreen.de/imprint" target="_blank">
            http://www.cyreen.de/imprint
          </a>
          . By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal
          Terms. Please be advised the Services are hosted in Germany. If you access the Services from any other region of
          the world with laws or other requirements governing personal data collection, use, or disclosure that differ from
          applicable laws in Germany, then through your continued use of the Services, you are transferring your data to
          Germany, and you expressly consent to have your data transferred to and processed in Germany.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="copyright_infringement">
          <strong>10. COPYRIGHT INFRINGEMENTS</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We respect the intellectual property rights of others. If you believe that any material available on or through
          the Services infringes upon any copyright you own or control, please immediately notify us using the contact
          information provided below (a "Notification"). A copy of your Notification will be sent to the person who posted
          or stored the material addressed in the Notification. Please be advised that pursuant to applicable law you may be
          held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that
          material located on or linked to by the Services infringes your copyright, you should consider first contacting an
          attorney.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="terms_and_termination">
          <strong>11. TERM AND TERMINATION</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER
          PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
          DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR
          FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
          THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE
          SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN
          OUR SOLE DISCRETION.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new
          account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on
          behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="modifications_and_interruptions">
          <strong>12. MODIFICATIONS AND INTERRUPTIONS</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at
          our sole discretion without notice. However, we have no obligation to update any information on our Services. We
          will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of
          the Services.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other
          problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We
          reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or
          for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or
          inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the
          Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to
          supply any corrections, updates, or releases in connection therewith.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="governing_law">
          <strong>13. GOVERNING LAW</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          These Legal Terms are governed by and interpreted following the laws of Germany, and the use of the United Nations
          Convention of Contracts for the International Sales of Goods is expressly excluded. If your habitual residence is
          in the EU, and you are a consumer, you additionally possess the protection provided to you by obligatory
          provisions of the law in your country to residence. Cyreen GmbH and yourself both agree to submit to the
          non-exclusive jurisdiction of the courts of Frankfurt am Main, which means that you may make a claim to defend
          your consumer protection rights in regards to these Legal Terms in Germany, or in the EU country in which you
          reside.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="dispute_resolution">
          <strong>14. DISPUTE RESOLUTION</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          The European Commission provides an{" "}
          <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank">
            online dispute resolution platform
          </a>{" "}
          , which you can access. If you would like to bring this subject to our attention, please contact us.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="corrections">
          <strong>15. CORRECTIONS</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including
          descriptions, pricing, availability, and various other information. We reserve the right to correct any errors,
          inaccuracies, or omissions and to change or update the information on the Services at any time, without prior
          notice.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="disclaimer">
          <strong>16. DISCLAIMER</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT
          YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
          CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
          ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS
          LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
          FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
          ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
          TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED
          TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
          FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
          OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
          PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY
          WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
          WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
          SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
          YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="limitations_of_liability">
          <strong>17. LIMITATIONS OF LIABILITY</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
          INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE,
          LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR
          ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO 100€. CERTAIN US
          STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
          CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO
          YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
          INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE,
          LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR
          ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO 100€. CERTAIN US
          STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
          CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO
          YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="identification">
          <strong>18. INDEMNIFICATION</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
          respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or
          demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1)
          your Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any breach of your
          representations and warranties set forth in these Legal Terms; (5) your violation of the rights of a third party,
          including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of
          the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at
          your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify
          us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts
          to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming
          aware of it.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="user_data">
          <strong>19. USER DATA</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the
          Services, as well as data relating to your use of the Services. Although we perform regular routine backups of
          data, you are solely responsible for all data that you transmit or that relates to any activity you have
          undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any
          such data, and you hereby waive any right of action against us arising from any such loss or corruption of such
          data.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="e_communication">
          <strong>20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You
          consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other
          communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that
          such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
          OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED
          BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules,
          ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by any means other than electronic means.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="california_user">
          <strong>21. CALIFORNIA USERS AND RESIDENTS</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the
          Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market
          Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="misc">
          <strong>22. MISCELLANEOUS</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services
          constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right
          or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms
          operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others
          at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any
          cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is determined to
          be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal
          Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture,
          partnership, employment or agency relationship created between you and us as a result of these Legal Terms or use
          of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted
          them. You hereby waive any and all defenses you may have based on the electronic form of these Legal Terms and the
          lack of signing by the parties hereto to execute these Legal Terms.
        </Typography>
        <Box height={20} />
        <Typography variant="body1" id="contact_us">
          <strong>23. CONTACT US</strong>
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          In order to resolve a complaint regarding the Services or to receive further information regarding use of the
          Services, please contact us at:
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          <strong>Cyreen GmbH</strong>
          <br />
          <strong>Schaumainkai 87-91</strong>
          <br />
          <strong>Frankfurt am Main, Hessen 60596 Germany</strong>
          <br />
          <strong>Phone: +49 (613) 45898677 0</strong>
          <br />
          <strong>info@cyreen.de</strong>
          <br />
        </Typography>
        <Box height={20} />
        <Typography variant="body1">
          These terms of use were created using Termly's{" "}
          <a href="https://termly.io/products/terms-and-conditions-generator/" target="_blank">
            Terms and Conditions Generator.
          </a>
        </Typography>
        <Box height={20} />
        {/* Add more content here as needed */}
      </Box>
    </Container>
  );
};

export default TermsOfUse;
