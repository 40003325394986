import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import { filterRangeValue } from "../../api_calls/TableauVisuals";
import DurationFilter from "./Filters/DurationFilter";
import { StickyTopRowStack, StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import StoreFilter from "../../components/commonComponents/Filters/StoreFilter";
import DateRangeFilter from "../../components/commonComponents/Filters/DateRangeFilter";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkWorkSpaceFromCookies } from "../../api_calls/userManagement";
import SingleSelectStoreFilter from "../../components/commonComponents/Filters/SingleSelectStoreFilter";
import { useSelector } from "react-redux";
import {
  dbFormatDate,
  formatToStandardDate,
  resetBrandReportView,
  standardFormatToday,
  storeListOptionsForTableauFilter,
  yesterdayDate,
} from "../../api_calls/utils";

const Duration = () => {
  const userStateDate = useSelector((state) => state.userSelections);
  const [workSpaceId, setWorkSpaceId] = React.useState("");
  const startDate = new Date("2023-02-01");
  // typeof userStateDate.date_range_start_date !== "undefined"
  //   ? new Date(userStateDate.date_range_start_date)
  //   : new Date("2023-02-01");

  const today = standardFormatToday();
  const yesterday = formatToStandardDate(today);
  yesterday.setDate(today.getDate() - 1);

  React.useEffect(() => {
    const checkWorkSpaces = checkWorkSpaceFromCookies();
    if (
      checkWorkSpaces.success === true &&
      typeof checkWorkSpaces.current_workspace !== "undefined" &&
      typeof checkWorkSpaces.current_workspace.id !== "undefined"
    ) {
      setWorkSpaceId(checkWorkSpaces.current_workspace.id);
    }
    resetBrandReportView();
  }, []);
  return (
    <StyledMainBox component="main">
      {/* ------------- filters -------------- */}
      <StickyTopRowStack spacing={4} direction={"row"}>
        {/* ----------- duration filter ---------- */}
        {/* <Box sx={{ width: 250 }}>
          <DurationFilter vizId={"durationViz"} />
        </Box> */}
        {/* ------- stores filter ----------- */}
        <Box sx={{ width: 250 }}>
          <StoreFilter fieldName={"Name Store"} usedFor={"retail"} vizId={"durationViz"} />
        </Box>
        {/* ------------ day timestamp filter ------------ */}
        {typeof userStateDate.date_range_start_date !== "undefined" && (
          <Box sx={{ width: 250 }}>
            <DateRangeFilter
              fieldName={"DAY(Timestamp)"}
              minSelectableDate={startDate}
              maxSelectableDate={new Date("2023-10-09")}
              onValueChange={filterRangeValue}
              vizId={"durationViz"}
            />
          </Box>
        )}
      </StickyTopRowStack>
      {/* ---------- to have a gap between top bar and main container ---------- */}
      <Box height={1} />

      {/* ----------- tableau dashboard ---------- */}
      <Grid container spacing={2}>
        {workSpaceId !== "" && typeof userStateDate.stores_list !== "undefined" && (
          <TableauDash
            vizId="durationViz"
            vizUrl={
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" + workSpaceId + "_Retail_Analytics/Duration"
            }
            applyFilter={true}
            filterConfig={{
              store: {
                name: "Name Store",
                values: storeListOptionsForTableauFilter("retail"),
              },
            }}
          />
        )}
      </Grid>
    </StyledMainBox>
  );
};

export default AccessControl(Duration, "retail_analytics");
