import React from "react";
import { Box } from "@mui/material";
import AnalysisGroup from "./shared/AnalysisGroup";
import { StickyTopRowStack } from "../../../components/commonComponents/StyledComponents/styled";
import AnalysisGroupSingle from "./shared/AnalysisGroupSingle";

const SinglePerformanceFilters = ({ vizId }) => {
  return (
    <StickyTopRowStack direction={"row"} spacing={4}>
      <Box sx={{ width: 220 }}>
        <AnalysisGroupSingle vizId={vizId} multiSelect={false} />
      </Box>
    </StickyTopRowStack>
  );
};

export default SinglePerformanceFilters;