import React from "react";
import { Box, Stack } from "@mui/material";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import { Add } from "@mui/icons-material";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AddNewClip from "./ModalContent/AddClip";
import mediaClip from "../../images/campaign/clip/REWE_Azhari_ZitronenimWasser.mp4";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";

import ClipsListing from "./listings/ClipsListing";
import StoreHierarchy from "./ModalContent/StoreHierarchy";
import { resetClipsData } from "../../api_calls/clipRequests";
import ClipListFilters from "./filters/ClipListFilters";
import { pageAccessAllowed } from "../../api_calls/userManagement";
import { resetBrandReportView } from "../../api_calls/utils";

const Clips = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const checkEditAccess = pageAccessAllowed("campaigns", "can_edit");
  const closeModal = () => {
    resetClipsData();
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  React.useEffect(() => {
    resetBrandReportView();
  }, []);

  return (
    <>
      <StyledMainBox component="main">
        {/* <Box height={30} /> */}
        <h1>Clips</h1>
        {/* ============ chose data fields button =========== */}
        {checkEditAccess.success === true && (
          <Stack direction="row" spacing={2}>
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
              label={"New Clip"}
              icon={<Add />}
            />
          </Stack>
        )}

        {/* --------------- filters ------------- */}
        <Box height={30} />
        <ClipListFilters />
        {/* ============== campaign listing ============ */}
        <Box height={30} />
        <ClipsListing />
      </StyledMainBox>
      {/* ============ modal popup to add clips ========== */}
      {isModalOpen && (
        <AddNewClip open={isModalOpen} onClose={closeModal} size={"md"} closeModal={closeModal} usedFor={"add"} />
      )}
    </>
  );
};

export default AccessControl(Clips, "campaigns");
